module.exports = {

  // Generated by apglib/style.js 
  CLASS_MONOSPACE: 'apg-mono',
  CLASS_ACTIVE: 'apg-active',
  CLASS_EMPTY: 'apg-empty',
  CLASS_MATCH: 'apg-match',
  CLASS_NOMATCH: 'apg-nomatch',
  CLASS_LOOKAHEAD: 'apg-lh-match',
  CLASS_LOOKBEHIND: 'apg-lb-match',
  CLASS_REMAINDER: 'apg-remainder',
  CLASS_CTRLCHAR: 'apg-ctrl-char',
  CLASS_LINEEND: 'apg-line-end',
  CLASS_ERROR: 'apg-error',
  CLASS_PHRASE: 'apg-phrase',
  CLASS_EMPTYPHRASE: 'apg-empty-phrase',
  CLASS_STATE: 'apg-state',
  CLASS_STATS: 'apg-stats',
  CLASS_TRACE: 'apg-trace',
  CLASS_GRAMMAR: 'apg-grammar',
  CLASS_RULES: 'apg-rules',
  CLASS_RULESLINK: 'apg-rules-link',
  CLASS_ATTRIBUTES: 'apg-attrs',
}
